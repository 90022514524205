import enums from '@/enums';
import CampaignSpecifiedChart from '@/views/modules/campaigns/components/campaign-specified-chart/CampaignSpecifiedChart.vue';
import commonListCreateOrEditWithAgGrid from '@/views/modules/_mixins/commonListCreateOrEditWithAgGrid';
import CellRendererDeliveryTime from '@/views/modules/campaigns/components/cell-renderers/CampaignCellRendererDeliveryTime.vue';

/**
 * This provides methods used for list campaigns
 * @mixin views/modules/campaigns/campaign-list
 *
 * @vue-prop {Object} statusFilter - status to filter campaigns
 * @vue-event {void} confirmDeleteCampaign - called to delete many campaigns
 */
export default {
  components: {
    CampaignSpecifiedChart,
    CellRendererDeliveryTime,
  },
  mixins: [commonListCreateOrEditWithAgGrid],
  props: {
    statusFilter: {
      type: Object,
      required: false,
    },
    listType: {
      required: true,
      validator(type) {
        return Object.values(enums.Campaign.ListType).includes(type);
      },
    },
  },
  data() {
    return {
      modalsDelay: 0,
      dontConfirmCloseCreateOrEdit: true,
      viewDisabled: this.listType !== this.$enums.Campaign.ListType.History,
      cloneDisabled: true,
      editDisabled: this.listType === this.$enums.Campaign.ListType.History,
      deleteDisabled: this.listType === this.$enums.Campaign.ListType.History,
    };
  },
  computed: {
    campaignMultipleActionOptions() {
      switch (this.listType) {
        case this.$enums.Campaign.ListType.History:
          return this.defaultMultipleActionOptions.filter(
            (option) => option.event !== this.$enums.Operation.DELETE,
          );

        case this.$enums.Campaign.ListType.Drafts:
          return this.defaultMultipleActionOptions.filter(
            (option) => option.event !== this.$enums.Operation.EXPORT,
          );

        case this.$enums.Campaign.ListType.Scheduled:
          return this.defaultMultipleActionOptions.map((option) => {
            if (option.event === this.$enums.Operation.DELETE) {
              return {
                ...option,
                label: this.$t('$General.Cancel'),
                icon: 'XIcon',
              };
            }

            return option;
          });

        default:
          return this.defaultMultipleActionOptions;
      }
    },
  },
  beforeMount() {
    if (this.listType !== this.$enums.Campaign.ListType.History) {
      this.deleteColumnDef('bounced');
      this.deleteColumnDef('outbound');
      this.deleteColumnDef('status');

      if (this.listType === this.$enums.Campaign.ListType.Scheduled) {
        const index = this.columnDefs.findIndex((col) => col.colId === 'message');

        if (index !== -1) {
          this.columnDefs.splice(
            index + 1,
            0,
            {
              colId: 'DeliveryTime',
              headerName: this.$tc('$General.DeliveryTime'),
              field: 'localStartDate',
              filter: null,
              sortable: false,
              minWidth: 300,
              maxWidth: 400,
              cellRendererFramework: 'CellRendererDeliveryTime',
            },
          );
        }
      }
    }
  },
  beforeDestroy() {
    document.querySelector('.vs-tooltip').style = 'display: none;';
  },
  methods: {
    confirmDeleteCampaigns() {
      if (this.listType !== this.$enums.Campaign.ListType.History) {
        this.confirmDeleteRecordsDialogParams = {
          color: 'danger',
          title: this.listType === this.$enums.Campaign.ListType.Scheduled
            ? this.$t('$Dialogs.ConfirmCancelTitle')
            : this.$t('$Dialogs.ConfirmDeleteTitle'),
          text: this.listType === this.$enums.Campaign.ListType.Scheduled
            ? this.$tc('$Dialogs.ConfirmGeneralCancelMsg', this.rowsSelectedCountWithAllSelection, {
              count: this.rowsSelectedCountWithAllSelection,
              entity: this.$tc(`$Entities.${this.entity}`, this.rowsSelectedCountWithAllSelection),
            })
            : this.$tc('$Dialogs.ConfirmGeneralDeleteMsg', this.rowsSelectedCountWithAllSelection, {
              count: this.rowsSelectedCountWithAllSelection,
              entity: this.$tc(`$Entities.${this.entity}`, this.rowsSelectedCountWithAllSelection),
            }),
          acceptText: this.listType === this.$enums.Campaign.ListType.Scheduled
            ? this.$t('$General.Confirm')
            : this.$t('$General.Delete'),
          cancelText: this.listType === this.$enums.Campaign.ListType.Scheduled
            ? this.$t('$General.Close')
            : this.$t('$General.Cancel'),
        };

        this.confirmDeleteRecords();
      }
    },
    onRowClicked(e) {
      const campaignData = e.node.data;

      if (this.$mq !== this.$enums.mqBreakpoints.LAPTOP
        && this.$mq !== this.$enums.mqBreakpoints.DESKTOP) {
        if (campaignData.status === this.$enums.Campaign.Status.PENDING
          || campaignData.status === this.$enums.Campaign.Status.DRAFT) {
          this.editRecord(campaignData);
        } else if (campaignData.status === this.$enums.Campaign.Status.COMPLETED) {
          this.viewRecord(campaignData);
        }
      }
    },
    onRowDoubleClicked(e) {
      const campaignData = e.node.data;

      if (campaignData.status === this.$enums.Campaign.Status.PENDING
        || campaignData.status === this.$enums.Campaign.Status.DRAFT) {
        this.editRecord(campaignData);
      } else if (campaignData.status === this.$enums.Campaign.Status.COMPLETED) {
        this.viewRecord(campaignData);
      }
    },
  },
};
