<template>
  <common-cell-renderer-actions
    :actions="filteredActionsByCampaignStatus"
    clone-disabled
    @view="viewRecord()"
    @edit="editRecord()"
    @clone="cloneRecord()"
    @delete="confirmDeleteRecord()"
    @resend="resendRecord()">
  </common-cell-renderer-actions>
</template>

<script>
import commonSingleCellRendererActions from '@/views/modules/_mixins/commonSingleCellRendererActions';
import CommonCellRendererActions from '@/views/modules/_components/cell-renderer/CommonCellRendererActions.vue';

/**
 * Cell renderer actions to campaigns
 *
 * @module views/modules/campaigns/CampaignCellRenderActions
 * @author Dilan Useche <dilan8810@gmail.com>
 */
export default {
  name: 'CampaignCellRenderActions',
  components: {
    CommonCellRendererActions,
  },
  mixins: [commonSingleCellRendererActions],
  computed: {
    confirmDeleteRecordDialogParams() {
      if (this.dataRowRecord && this.dataRowRecord.status === this.$enums.Campaign.Status.PENDING) {
        return {
          color: 'danger',
          title: this.$t('$Dialogs.ConfirmCancelTitle'),
          text: this.$t('$Dialogs.ConfirmSpecificCancelMsg', { entity: this.nameRowRecord }),
          acceptText: this.$t('$General.Confirm'),
          cancelText: this.$t('$General.Close'),
        };
      }

      return {};
    },
    deleteSuccessNotificationParams() {
      if (this.dataRowRecord && this.dataRowRecord.status === this.$enums.Campaign.Status.PENDING) {
        return {
          $title: '$Notifications.CanceledTitle',
          $msg: '$Notifications.CanceledMsg',
        };
      }

      return {};
    },
    filteredActionsByCampaignStatus() {
      const bannedActions = [];

      if (!this.dataRowRecord) {
        return this.mappedActionsByCampaignStatus;
      }

      switch (this.dataRowRecord.status) {
        case this.$enums.Campaign.Status.COMPLETED:
          bannedActions.push(this.$enums.Operation.EDIT);
          bannedActions.push(this.$enums.Operation.DELETE);
          break;
        case this.$enums.Campaign.Status.RUNNING:
          bannedActions.push(this.$enums.Operation.VIEW);
          bannedActions.push(this.$enums.Operation.EDIT);
          bannedActions.push(this.$enums.Operation.DELETE);
          bannedActions.push(this.$enums.Operation.RESEND);
          break;
        case this.$enums.Campaign.Status.PENDING:
        case this.$enums.Campaign.Status.DRAFT:
          bannedActions.push(this.$enums.Operation.VIEW);
          bannedActions.push(this.$enums.Operation.RESEND);
          break;
        case this.$enums.Campaign.Status.CANCELED:
          bannedActions.push(this.$enums.Operation.VIEW);
          bannedActions.push(this.$enums.Operation.EDIT);
          bannedActions.push(this.$enums.Operation.DELETE);
          break;

        default:
      }
      return this.mappedActionsByCampaignStatus.filter(
        (action) => !bannedActions.includes(action.name),
      );
    },
    mappedActionsByCampaignStatus() {
      if (this.dataRowRecord && this.dataRowRecord.status === this.$enums.Campaign.Status.PENDING) {
        return this.filteredActionsFromParent.map((action) => {
          if (action.name === this.$enums.Operation.DELETE) {
            return {
              ...action,
              color: 'danger',
              text: '$General.Cancel',
              icon: 'icon-x-circle',
              iconVue: 'XCircleIcon',
            };
          }

          return action;
        });
      }

      return this.filteredActionsFromParent;
    },
  },
};
</script>
