<template>
  <vs-chip
    v-if="params.value"
    class="ag-grid-cell-chip"
    :color="chipColor(params.value)">
    <feather-icon :icon="chipIcon(params.value)" svgClasses="h-5 w-5 mr-1" />
    <span v-if="params.value === $enums.Campaign.Status.CANCELED">
      <vs-dropdown
        v-if="params.value === $enums.Campaign.Status.CANCELED"
        vs-custom-content
        class="cursor-pointer"
        style="font-family: inherit">
          <span
            class="con-vs-chip-text"
            style="font-family: inherit"
          >
            {{ $t(`$Enums.Campaign.Status.${params.value}`) }}
          </span>

          <vs-dropdown-menu>
            <div class="p-3">
              <h5>{{ $t(`$Enums.Campaign.CancellationReason.${cancellationReason}`)}}</h5>

              <p>{{ $t(`CancellationReason.${cancellationReason}`)}}</p>
            </div>
          </vs-dropdown-menu>
        </vs-dropdown>
    </span>

    <span v-else>
      {{ $t(`$Enums.Campaign.Status.${params.value}`) }}
    </span>
  </vs-chip>
</template>

<script>
import enums from '@/enums';

/**
 * Cell renderer status to campaigns
 *
 * @module views/modules/campaigns/CampaignCellRendererStatus
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed {string} chipColor - color of chip
 * @vue-computed {string} chipColor - icon of chip
 */
export default {
  name: 'CampaignCellRendererStatus',
  i18n: {
    messages: {
      en: {
        CancellationReason: {
          [enums.Campaign.CancellationReason.MANUAL_CANCELLATION]: 'You have manually canceled this campaign before it was sent.',
          [enums.Campaign.CancellationReason.INSUFFICIENT_FUNDS]: 'This campaign has been canceled because you did not have enough funds to send it.',
        },
      },
    },
  },
  computed: {
    chipColor() {
      return (value) => {
        switch (value) {
          case this.$enums.Campaign.Status.PENDING:
          case this.$enums.Campaign.Status.RUNNING:
          case this.$enums.Campaign.Status.DRAFT:
            return 'warning';
          case this.$enums.Campaign.Status.COMPLETED:
            return 'success';
          case this.$enums.Campaign.Status.CANCELED:
            return 'danger';

          default:
            return '';
        }
      };
    },
    chipIcon() {
      return (value) => {
        switch (value) {
          case this.$enums.Campaign.Status.PENDING:
            return 'ClockIcon';
          case this.$enums.Campaign.Status.RUNNING:
            return 'PlayIcon';
          case this.$enums.Campaign.Status.DRAFT:
            return 'EditIcon';
          case this.$enums.Campaign.Status.COMPLETED:
            return 'CheckSquareIcon';
          case this.$enums.Campaign.Status.CANCELED:
            return this.cancellationReason === this.$enums.Campaign.CancellationReason.INSUFFICIENT_FUNDS ? 'AlertTriangleIcon' : 'XCircleIcon';

          default:
            return '';
        }
      };
    },
    cancellationReason() {
      if (this.params.data && this.params.data.cancellationReason) {
        return this.params.data.cancellationReason;
      }

      return '';
    },
  },
};
</script>
