<template>
  <div class="vx-card p-6">
    <base-ag-grid-header
      :rows-selected-count="rowsSelectedCountWithAllSelection"
      :filters-count="filtersAppliedCount"
      :filters-match.sync="filtersMatch"
      @reset-selection="setSelectedRowsNone()"
      @reset-filters="resetFilters()">
      <template v-slot:header-left>
        <items-per-page
          :count="count"
          :currentPage="currentPage"
          :paginationPageSize="paginationPageSize"
          @changed-page-size="paginationSetPageSize">
        </items-per-page>

        <list-action-drop-down
          v-if="isAnyRowsSelected"
          :options="campaignMultipleActionOptions"
          class="ml-3"
          @export="exportData()"
          @delete="confirmDeleteCampaigns()">
        </list-action-drop-down>
      </template>

      <template v-slot:header-right>
        <vs-button
          radius
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-refresh-cw"
          @click="resetDataSourceAndSelection()">
        </vs-button>
      </template>
    </base-ag-grid-header>

    <vs-alert
      :active.sync="selectionPageAlert"
      class="text-center"
      color="grey-dark"
      closable icon-pack="feather"
      close-icon="icon-x">
      {{ $t('$SharedByManyModules.SelectedAllPageInListMsg', {
      count: rowsSelectedCount,
      entity: $tc(`$Entities.${entity}`, rowsSelectedCount) }) }}
      <a
        href="#"
        @click.prevent="setSelectedRowsAll()">
        {{ $t('$SharedByManyModules.SelectAllInListMsg', {
        count: count,
        entity: $tc(`$Entities.${entity}`, count) }) }}
      </a>
    </vs-alert>

    <export-alert-info
      :entity="$tc(`$Entities.${entity}`, 2)"
      :exporting.sync="exportingList"
      :exported.sync="exportedList"
      :fileUrl="urlFileExported"
      :send-email="exportSendEmail"/>

    <ag-grid-vue
      :key="agGridKey"
      ref="agGridTable"
      :domLayout="domLayout"
      :style="gridStyle"
      :components="components"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :column-types="columnTypes"
      :getRowNodeId="getRowNodeId"
      :autoParamsRefresh="true"
      rowSelection="multiple"
      :animateRows="true"
      :suppressRowClickSelection="true"
      rowModelType="infinite"
      :pagination="hasPagination"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="suppressPaginationPanel"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
      :cacheBlockSize="cacheBlockSize"
      :maxBlocksInCache="maxBlocksInCache"
      @selection-changed="onSelectionChanged"
      @grid-ready="onGridReady"
      @model-updated="onModelUpdate"
      @grid-size-changed="onGridSizeChanged"
      @first-data-rendered="onFirstDataRendered"
      @row-clicked="onRowClicked"
      @row-double-clicked="onRowDoubleClicked"
      @filter-changed="onFilterChanged"
      @pagination-changed="onPaginationChanged">
    </ag-grid-vue>

    <vs-popup
      :title="exportModalTitle"
      :active.sync="activeModalToExport">
      <transition name="zoom-fade">
        <export-json-to-excel
          v-if="showExportComponent"
          :columns="getColumnsToExport"
          :data="rowsSelected"
          @close="activeModalToExport=false"
          @export="onExport">
        </export-json-to-excel>
      </transition>
    </vs-popup>

    <vs-popup
      fullscreen
      :title="$t('$CampaignsModules.DetailCampaignTitle')"
      :active.sync="activeModalView">
      <transition name="zoom-fade">
        <campaign-specified-chart
          v-if="showCreateOrEditComponent"
          :entity="entity"
          :campaign-type="$enums.Campaign.Type.SMS"
          :fetch-function="fetchSMSCampaign"
          :fetch-details-function="fetchSMSCampaignDetails"
          :model-payload="recordSelected"/>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import {
  mapActions, mapMutations, mapState,
} from 'vuex';
import { registerSocketEvent, unregisterSocketEvent } from '@/socket';

// mixins
import campaignListWithAgGrid from '@/views/modules/campaigns/common/mixins/campaignListWithAgGrid';

// Custom Components
import BaseAgGridHeader from '@/views/modules/_components/BaseAgGridHeader.vue';
import ItemsPerPage from '@/views/modules/_components/ItemsPerPage.vue';
import ExportAlertInfo from '@/views/modules/_components/ExportAlertInfo.vue';
import AgGridSelectionHeader from '@/views/modules/_components/AgGridSelectionHeader';
import ExportJsonToExcel from '@/views/modules/_components/ExportJsonToExcel.vue';
import ListActionDropDown from '@/views/modules/_components/ListActionDropDown.vue';

// Cell Renderer
import CellRendererActions from '@/views/modules/campaigns/components/cell-renderers/CampaignCellRenderActions.vue';
import CellRendererStatus from '@/views/modules/campaigns/components/cell-renderers/CampaignCellRendererStatus.vue';
import CellRendererLargeText from '@/views/modules/_components/cell-renderer/CellRendererLargeTextModal.vue';

/**
 * Component to list and manage SMS Campaigns
 *
 * @module views/modules/campaigns/sms-campaign/SMSCampaignList
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {number} actionsMinWidth - min with for action buttons
 * @vue-data {string} [entity='...'] - Name of the entity associated to list
 * @vue-data {boolean} [cellRenderer=false] - to indicate that it isn't cell renderer
 * @vue-data {number} [modalsDelay=0] - the delay to close modals inner components
 * @vue-data {boolean} [dontConfirmCloseCreateOrEdit=true] -
 * indicate if no confirm on close create or edit modal
 * @vue-data {Array.<Object>} columnDefs - Columns definition of ag grid to list
 * @vue-data {Object} components - Components to use to extend ag grid
 * @vue-data {Object} frameworkComponents - Framework components to extend ag grid
 * @vue-event {void} fetchAgGridData - fetch data to ag grid table
 * @vue-event {void} showCampaignCompletedNft - show notification for sms campaign completed
 */
export default {
  name: 'SMSCampaignList',
  i18n: {
    messages: {
      en: {
        $SMSCampaignCompletedNtf: {
          Title: 'SMS campaign completed',
          Text: 'The SMS campaign {name} was completed',
        },
      },
    },
  },
  components: {
    BaseAgGridHeader,
    ItemsPerPage,
    ListActionDropDown,
    ExportJsonToExcel,
    ExportAlertInfo,
    // eslint-disable-next-line vue/no-unused-components
    AgGridSelectionHeader,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererActions,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererStatus,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererLargeText,
  },
  mixins: [campaignListWithAgGrid],
  props: {
    actionsMinWidth: {
      type: Number,
      required: false,
      default: 50,
    },
  },
  data() {
    return {
      entity: this.$enums.Entity.SMS_CAMPAIGN,
      exportCollectionFunction: this.exportSMSCampaigns,
      deleteRecordFunction: this.removeSMSCampaign,
      deleteRecordsFunction: this.removeSMSCampaigns,
      resendDisabled: false,
      columnDefs: [
        {
          colId: 'name',
          headerName: this.$t('$General.Name'),
          field: 'name',
          filter: 'agTextColumnFilter',
          minWidth: 200,
          maxWidth: 1228,
          checkboxSelection: true,
          headerComponentParams: { checkboxSelection: true },
        },
        {
          colId: 'message',
          headerName: this.$tc('$General.Message'),
          field: 'message',
          filter: 'agTextColumnFilter',
          minWidth: 350,
          maxWidth: 1638,
          cellRendererFramework: 'CellRendererLargeText',
        },
        {
          colId: 'status',
          headerName: this.$t('$General.Status'),
          headerClass: 'center-herder-ag-grid',
          field: 'status',
          filter: false,
          minWidth: 150,
          maxWidth: 615,
          cellRendererFramework: 'CellRendererStatus',
        },
      ],
      components: {
        CellRendererActions,
        CellRendererStatus,
        CellRendererLargeText,
      },
      frameworkComponents: {
        agColumnHeader: AgGridSelectionHeader,
      },

      socketEventsListenerId: 'sms-campaign-list',
    };
  },
  computed: {
    ...mapState({
      draftCampaign: (state) => state.smsCampaign.draftCampaign,
    }),
    additionalActionsOnList() {
      return [{
        name: this.$enums.Operation.RESEND,
        color: 'primary',
        text: '$General.Resend',
        position: 'top',
        icon: 'icon-send',
        iconVue: 'SendIcon',
        activeCreateOrEdit: false,
        active: !this.resendDisabled,
      }];
    },
  },
  watch: {
    async activeModalCreateOrEdit(val) {
      if (val) {
        switch (this.operation) {
          case this.$enums.Operation.CREATE:
            this.showCreateOrEditComponent = false;
            await this.$router.push('/sms-campaigns/create');
            break;

          case this.$enums.Operation.RESEND:
            this.showCreateOrEditComponent = false;
            this.setCampaignPayload(this.recordSelected);

            if (this.recordSelected.isQuick) {
              await this.$router.push('/sms-campaigns/quick/resend');
            } else {
              await this.$router.push('/sms-campaigns/resend');
            }
            break;

          case this.$enums.Operation.EDIT:
            this.showCreateOrEditComponent = false;
            this.setCampaignPayload(this.recordSelected);
            await this.$router.push('/sms-campaigns/edit');
            break;

          case this.$enums.Operation.VIEW:
            this.activeModalView = true;
            break;

          default:
        }
      }
    },
    activeModalView(val) {
      if (!val) {
        this.activeModalCreateOrEdit = false;
      }
    },
  },
  mounted() {
    registerSocketEvent(this.$enums.SocketEvents.SMS_CAMPAIGN_UPDATED, (smsCampaign) => {
      if (smsCampaign.status === this.$enums.Campaign.Status.COMPLETED) {
        this.showCampaignCompletedNft(smsCampaign.name);
      }

      this.refreshRowNodeDataById(smsCampaign.id, smsCampaign);
    },
    this.socketEventsListenerId,
    true);
  },
  beforeDestroy() {
    unregisterSocketEvent(
      this.$enums.SocketEvents.SMS_CAMPAIGN_UPDATED,
      this.socketEventsListenerId,
    );
  },
  methods: {
    ...mapActions({
      fetchAllSMSCampaigns: 'smsCampaign/fetchAllSMSCampaigns',
      fetchSMSCampaign: 'smsCampaign/fetchSMSCampaign',
      fetchSMSCampaignDetails: 'smsCampaign/fetchSMSCampaignDetails',
      exportSMSCampaigns: 'smsCampaign/exportFile',
      removeSMSCampaign: 'smsCampaign/removeSMSCampaign',
      removeSMSCampaigns: 'smsCampaign/removeSMSCampaigns',
    }),
    ...mapMutations({
      setCampaignPayload: 'smsCampaign/SET_SMS_CAMPAIGN_PAYLOAD',
    }),
    async fetchAgGridData(params) {
      let filters = {
        ...params.filters,
      };
      let { filtersMatch } = params;

      if (this.statusFilter) {
        // eslint-disable-next-line no-param-reassign
        filters = {
          status: this.statusFilter,
          agGridFilters: {
            filterType: this.$enums.AppFilterType.CONDITIONAL,
            filtersMatch: params.filtersMatch,
            filter: params.filters,
          },
        };

        filtersMatch = this.$enums.AppFilterMathType.ALL;
      }

      return this.fetchAllSMSCampaigns({
        ...params,
        filters,
        filtersMatch,
        populate: [
          { path: 'senderId', select: 'id dialCode phoneSignificant name' },
        ],
      });
    },
    showCampaignCompletedNft(name) {
      this.$showSuccessActionNotification({
        title: this.$t('$SMSCampaignCompletedNtf.Title'),
        text: this.$t('$SMSCampaignCompletedNtf.Text', { name }),
      });
    },
    resendRecord(recordSelected = null) {
      this.operation = this.$enums.Operation.RESEND;
      this.recordSelected = recordSelected;
      this.activeModalCreateOrEdit = true;
    },
  },
};
</script>
