<template>
<span>{{ localStartDate }} {{ timeZone }}</span>
</template>

<script>
import moment from 'moment';

export default {
  name: 'CampaignCellRendererDeliveryTime',
  computed: {
    localStartDate() {
      if (this.params.data && this.params.data.localStartDate) {
        return moment.utc(this.params.data.localStartDate).format('DD MMM YYYY h:mm A');
      }

      return '';
    },
    timeZone() {
      if (this.params.data && this.params.data.timeZone) {
        return this.params.data.timeZone;
      }

      return '';
    },
  },
};
</script>

<style scoped>

</style>
